/* eslint-disable eqeqeq */
<template>
  <div class="main">
    <div class="topline"></div>
    <div class="content">
      <div class="footer-left">
        <img
          src="../../../assets/image/登录页/科研人logo黑.png"
          alt=""
        >
        <div class="text">
          <div class="top">
            <span>
              <router-link :to="{path:'aboutUs'}">关于我们</router-link>
            </span>
            <span>
              <router-link :to="{path:'suggest'}">问题&建议</router-link>
            </span>
            <span>
              <router-link :to="{path:'declarationAndTerms'}">声明&条款</router-link>
            </span>
          </div>
          <div class="bottom">
            <span>科研人（www.keyanpro.com）版权所有</span>
          </div>
          <!-- <div class="bottom">
            <span>京ICP备2021003752号</span>
          </div> -->
        </div>
      </div>
      <div class="footer-right">
        <div class="qr-code">
          <div class="bimage">
            <img
              src="../../../assets/image/关于我们_slices/1234.png"
              alt=""
            >
          </div>
          <div
            class="image"
            @mouseenter="tagmouseenter($event)"
            @mouseleave="tagmouseleave($event)"
          >
            <img
              src="../../../assets/image/关于我们_slices/1234.png"
              alt=""
            >
          </div>
          <div class="text">登录小程序</div>
        </div>
        <div class="qr-code">
          <div class="bimage">
            <img
              src="../../../assets/image/关注公众号.png"
              alt=""
            >
          </div>
          <div
            class="image"
            @mouseenter="tagmouseenter($event)"
            @mouseleave="tagmouseleave($event)"
          >
            <img
              src="../../../assets/image/关注公众号.png"
              alt=""
            >
          </div>
          <div class="text">
            关注公众号
          </div>
        </div>
        <!-- :style="{transform: 'scale('+$store.state.suofangbi+')'}" -->
        <div class="qr-code">
          <div class="bimage ">
            <!-- <img
              src="../../../assets/image/推荐给好友.png"
              alt=""
            > -->
            <div class='turnout-wrapper turnin-wrapper'>
              <!-- <vue-qr
                ref="Qrcode"
                :logoSrc='imgUrl'
                :text="codeUrl"
                :size="119"
                :margin="0"
                qid="testQrId"
              ></vue-qr> -->
              <img
              src="../../../assets/image/关注公众号1.png"
              alt=""
            >
            </div>
          </div>
          <!-- :style="{transform: 'scale('+$store.state.suofangbi+')'}" -->
          <div
            class="image tjximg"
            @mouseenter="tagmouseenter($event)"
            @mouseleave="tagmouseleave($event)"
          >
            <!-- <img
              src="../../../assets/image/推荐给好友.png"
              alt=""
            > -->
            <div class='turnout-wrapper turnin-wrapper '>
              <div style="display:none;">
                <vue-qr
                ref="Qrcode"
                :logoSrc='imgUrl'
                :text="codeUrl"
                :size="56"
                :margin="0"
                qid="testQrId"
              ></vue-qr>
              </div>
              <img
              src="../../../assets/image/关注公众号1.png"
              alt=""
            >
            </div>

          </div>
          <div class="text">联系客服</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import QRCode from 'qrcodejs2'
import vueQr from 'vue-qr'
export default {
  data () {
    return {
      address: '',
      codeUrl: '我好看吗？', // 二维码地址或内容
      imgUrl: require('../../../assets/image/vip/二维码logo.jpg'), // 二维码中间的logo图
      exportLink: '',
      downloadFilename: '' // 下载的图片文件名
    }
  },
  watch: {
    $route (to, from) {
      console.log('路由变化参数打印', to.path)
      // let url = ''
      // eslint-disable-next-line eqeqeq
      // if (to.path == '/pc/content/politicsInTheTender') {
      //   url = this.$store.state.h5baseurl + '/pages/home/zclist'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/reportingGuidelines') {
      //   url = this.$store.state.h5baseurl + '/pages/home/sblist'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/scientificResearchProject') {
      //   url = this.$store.state.h5baseurl + '/pages/home/kylist'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/depthOfTheArticle') {
      //   url = this.$store.state.h5baseurl + '/pages/home/sdlist'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/myRecentInformation') {
      //   url = this.$store.state.h5baseurl + '/pages/my/history'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/mySubscription') {
      //   url = this.$store.state.h5baseurl + '/'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/aboutUs') {
      //   url = this.$store.state.h5baseurl + '/'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/suggest') {
      //   url = this.$store.state.h5baseurl + '/'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/declarationAndTerms') {
      //   url = this.$store.state.h5baseurl + '/'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/myCollectionarticles') {
      //   url = this.$store.state.h5baseurl + '/pages/my/collectList'
      //   // eslint-disable-next-line eqeqeq
      // } else if (to.path == '/pc/content/homePage') {
      //   url = this.$store.state.h5baseurl + '/'
      // } else if (to.path.indexOf('/pc/content/reportingGuidelinesDetails') !== -1) {
      //   var str = to.path
      //   str = str.match(/\/pc\/content\/reportingGuidelinesDetails(\S*)/)[1]
      //   url = this.$store.state.h5baseurl + '/pages/home/zndetails?id=' + str
      // } else if (to.path.indexOf('/pc/content/politicsInTheTenderDetails') !== -1) {
      //   // eslint-disable-next-line no-redeclare
      //   var str = to.path
      //   str = str.match(/\/pc\/content\/politicsInTheTenderDetails(\S*)/)[1]
      //   url = this.$store.state.h5baseurl + '/pages/home/zsdetails?id=' + str
      // } else if (to.path.indexOf('/pc/content/scientificResearchProjectDetails') !== -1) {
      //   // eslint-disable-next-line no-redeclare
      //   var str = to.path
      //   str = str.match(/\/pc\/content\/scientificResearchProjectDetails(\S*)/)[1]
      //   url = this.$store.state.h5baseurl + '/pages/home/kydetails?id=' + str
      // } else if (to.path.indexOf('/pc/content/depthOfTheArticleDetails') !== -1) {
      //   // eslint-disable-next-line no-redeclare
      //   var str = to.path
      //   str = str.match(/\/pc\/content\/depthOfTheArticleDetails(\S*)/)[1]
      //   url = this.$store.state.h5baseurl + '/pages/home/details3?id=' + str
      // }
      // this.$refs.paycode.innerHTML = ''
      // this.qrcode(url)
      this.qrcode()
    }
  },
  mounted () {
    this.address = this.$route.params.address
    this.qrcode()
  },
  components: {
    vueQr
  },
  methods: {

    qrcode (url1) {
      var scene = this.$store.state.userid
      var url2 = this.$store.state.h5baseurl + '&scene=' + scene + '&wid=' + this.$route.params.id

      // console.log('路由地址', location.href)
      // console.log('刚进入url', url1)
      // // if ()
      // console.log('路由刚进入参数打印', this.$router.app._route.fullPath)
      // eslint-disable-next-line eqeqeq

      // eslint-disable-next-line eqeqeq
      // if (this.$router.app._route.fullPath == '/pc/content/politicsInTheTender') {
      //   url = this.$store.state.h5baseurl + '/pages/home/zclist'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/reportingGuidelines') {
      //   url = this.$store.state.h5baseurl + '/pages/home/sblist'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/scientificResearchProject') {
      //   url = this.$store.state.h5baseurl + '/pages/home/kylist'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/depthOfTheArticle') {
      //   url = this.$store.state.h5baseurl + '/pages/home/sdlist'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/myRecentInformation') {
      //   url = this.$store.state.h5baseurl + '/pages/my/history'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/mySubscription') {
      //   url = this.$store.state.h5baseurl + '/'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/aboutUs') {
      //   url = this.$store.state.h5baseurl + '/'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/suggest') {
      //   url = this.$store.state.h5baseurl + '/'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/declarationAndTerms') {
      //   url = this.$store.state.h5baseurl + '/'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/myCollectionarticles') {
      //   url = this.$store.state.h5baseurl + '/pages/my/collectList'
      //   // eslint-disable-next-line eqeqeq
      // } else if (this.$router.app._route.fullPath == '/pc/content/homePage') {
      //   url = this.$store.state.h5baseurl + '/'
      // } else if (this.$router.app._route.fullPath.indexOf('/pc/content/reportingGuidelinesDetails') !== -1) {
      //   var str = this.$router.app._route.fullPath
      //   str = str.match(/\/pc\/content\/reportingGuidelinesDetails(\S*)/)[1]
      //   url = this.$store.state.h5baseurl + '/pages/home/zndetails?id=' + str
      // } else if (this.$router.app._route.fullPath.indexOf('/pc/content/politicsInTheTenderDetails') !== -1) {
      //   // eslint-disable-next-line no-redeclare
      //   var str = this.$router.app._route.fullPath
      //   str = str.match(/\/pc\/content\/politicsInTheTenderDetails(\S*)/)[1]
      //   url = this.$store.state.h5baseurl + '/pages/home/zsdetails?id=' + str
      // } else if (this.$router.app._route.fullPath.indexOf('/pc/content/scientificResearchProjectDetails') !== -1) {
      //   // eslint-disable-next-line no-redeclare
      //   var str = this.$router.app._route.fullPath
      //   str = str.match(/\/pc\/content\/scientificResearchProjectDetails(\S*)/)[1]
      //   url = this.$store.state.h5baseurl + '/pages/home/kydetails?id=' + str
      // } else if (this.$router.app._route.fullPath.indexOf('/pc/content/depthOfTheArticleDetails') !== -1) {
      //   // eslint-disable-next-line no-redeclare
      //   var str = this.$router.app._route.fullPath
      //   str = str.match(/\/pc\/content\/depthOfTheArticleDetails(\S*)/)[1]
      //   url = this.$store.state.h5baseurl + '/pages/home/details3?id=' + str
      // } else {
      //   url = url1
      // }
      // this.$refs.paycode.innerHTML = ''
      // this.$refs.bpaycode.innerHTML = ''

      const cc = this.$route.params.address
      console.log('当前路由地址', cc)
      this.$data.codeUrl = url2
      // eslint-disable-next-line no-unused-vars
      // const qrcode = new QRCode('qrcode', {
      //   width: 56, // 设置宽度，单位像素
      //   height: 56, // 设置高度，单位像素
      //   text: url // 设置二维码内容或跳转地址,
      // })
      // eslint-disable-next-line no-unused-vars
      // const qrcode1 = new QRCode('qrcode1', {
      //   width: 120, // 设置宽度，单位像素
      //   height: 120, // 设置高度，单位像素
      //   text: url // 设置二维码内容或跳转地址,
      // })
    },
    // 鼠标移入大图
    tagmouseenter (event) {
      console.log(event.currentTarget.previousElementSibling.classList)
      event.currentTarget.previousElementSibling.classList.add('show')
    },
    // 鼠标移除隐藏大图
    tagmouseleave (event) {
      console.log(event.currentTarget.previousElementSibling.classList)
      event.currentTarget.previousElementSibling.classList.remove('show')
    }
  }
}
</script>

<style lang="scss" scoped>
.turnout-wrapper.turnin-wrapper {
  border: 1px solid #fff;
}
.show {
  display: block !important;
}
.main {
  .topline {
    height: 7px;
    background-color: #5e068c;
  }
  width: 1920px !important;
  background-color: #0a0a0a !important;
  .content {
    background-color: #0a0a0a !important;
    width: 1200px;
    height: 110px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-left {
      display: flex;
      justify-content: left;
      align-items: center;
      img {
        margin-right: 53px;
        height: 61px;
      }
      .text {
        .top {
          display: flex;
          justify-content: left;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 21px;
          color: #a1a1a1;
          opacity: 1;
          margin-bottom: 10px;
          a {
            color: #a1a1a1;
          }
          span {
            margin-right: 30px;
          }
        }
        .bottom {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 21px;
          color: #4f4f4f;
          opacity: 1;
        }
      }
    }
    .footer-right {
      display: flex;
      justify-content: left;
      align-items: center;
      .qr-code {
        canvas {
          width: 120rem !important;
        }
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        margin-left: 35px;
        .bimage {
          // width: 120px;
          // height: 120px;
          display: none;
          border: 4px solid #5e068c;
          position: absolute;
          top: -158px;
          z-index: 9;

          img {
            width: 120px;
            height: 120px;
          }
        }
        .image {margin-bottom:10px;
          canvas {
            height: 70px !important;
            width: 70px !important;
          }
          img {
            width: 70px;
            height: 70px;
          }
        }
        .tjximg {
        }
        .text {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 21px;
          color: #a1a1a1;
          opacity: 1;
        }
      }
    }
  }
}
</style>
