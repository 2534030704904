<template>
  <div class="main">
    <div id="header">
      <nav-header></nav-header>
    </div>
    <div id="content">
      <router-view></router-view>
    </div>
    <div id="footer">
      <nav-footer></nav-footer>
    </div>
  </div>
</template>

<script>
import navHeader from '../../components/pc/index/header'
import navFooter from '../../components/pc/index/footer'
export default {
  components: {
    navHeader,
    navFooter
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 1920px;
  background-color: #fff;
  margin: 0 auto;
  #header {
    border-bottom: 3px solid #f8f8f8;
  }
  #footer {
    height: 110px;
  }
}
</style>
